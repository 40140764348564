import * as Yup from "yup";
import { FieldItem } from "@shared/components/FormGenerator";
import { DOMAIN, DOMAIN_NAME, FORM_ERROR_MESSAGES } from "@shared/constants";
import { FieldItemType } from "@shared/interfaces";
import { WelcomeShape } from "@containers/Test/interface";

export const validationSchema: Yup.Schema<WelcomeShape> = Yup.object().shape({
  name: Yup.string()
    .required(FORM_ERROR_MESSAGES.REQUIRED)
    .min(3, "Min limit of characters 3")
    .max(50, "Max limit of characters 50"),
  domain: Yup.string()
    .oneOf([...Object.values(DOMAIN), "all"])
    .required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "name",
    label: "",
    placeholder: "Your Name",
    wrapperClass: "welcome-form__name",
  },
  {
    type: FieldItemType.SELECT,
    name: "domain",
    options: [
      {
        value: "all",
        label: "Practice All Domains",
      },
      ...Object.values(DOMAIN).map((domain) => ({
        value: domain,
        label: DOMAIN_NAME[domain],
      })),
    ],
    wrapperClass: "welcome-form__domain",
  },
];

export const initValues: WelcomeShape = {
  name: "",
  domain: "all",
};
