import React, { useEffect } from "react";
import { useRoutes, RouteObject, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NamesOfParentRoutes } from "@shared/constants";

import { AuthContainer } from "../Auth";
import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";
import { TestContainer } from "../Test/containers";

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.DASHBOARD}*`,
    element: (
      <AuthGuard>
        <TestContainer />
      </AuthGuard>
    ),
  },
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  const content = useRoutes(routes);

  return <Box className="App">{content}</Box>;
};

export default App;
