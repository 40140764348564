import { AnyAction, combineReducers } from "redux";
import { AppState } from "@shared/interfaces";

import { AuthReducer } from "../containers/Auth/store/reducer";
import { reducer as TestReducer } from "../containers/Test/store";
import { SharedReducer } from "../shared/store/reducer";

export default () => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    test: TestReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
