import { createAction } from "typesafe-actions";
import { LoadingType } from "@shared/interfaces";

import { Notification, ModalProps, OverlayProps } from "../interfaces";
import { SharedActionTypes } from "./constants";

export const startLoading = createAction(SharedActionTypes.START_LOADING)();
export const stopLoading = createAction(SharedActionTypes.STOP_LOADING)();
export const addLoadingType = createAction(SharedActionTypes.ADD_LOADING_TYPE)<LoadingType>();
export const removeLoadingType = createAction(SharedActionTypes.REMOVE_LOADING_TYPE)<string>();

export const addLoadingSection = createAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();

export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();

export const navigate = createAction(SharedActionTypes.NAVIGATE)<string>();

export const showOverlay = createAction(SharedActionTypes.SHOW_OVERLAY)<OverlayProps>();
export const hideOverlay = createAction(SharedActionTypes.HIDE_OVERLAY)();

export const showModal = createAction(SharedActionTypes.SHOW_MODAL)<ModalProps>();
export const hideModal = createAction(SharedActionTypes.HIDE_MODAL)();
