import React, { FC } from "react";
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { Session } from "@shared/models";
import { Button } from "@shared/components";
import balloons from "@assets/files/images/general/balloons.png";
import icecream from "@assets/files/images/general/icecream.png";
import { ReactComponent as Next } from "@assets/files/icons/general/next.svg";
import { DOMAIN, DOMAIN_NAME } from "@shared/constants";

import "./index.scss";

interface CongratsProps {
  session: Session;
  isFullFinished: boolean;
  viewDetailsHandler: () => void;
  viewLessonsHandler: () => void;
  newTestHandler?: () => void;
}
const Congrats: FC<CongratsProps> = ({
  session,
  isFullFinished,
  viewDetailsHandler,
  newTestHandler,
  viewLessonsHandler,
}) => {
  return (
    <Box className="congrats">
      <Image
        src={isFullFinished ? balloons : icecream}
        alt={isFullFinished ? "balloons" : "ice-cream"}
        className="congrats__image"
      />
      <Heading className="congrats__title">{isFullFinished ? "Congrats!" : "Hmmm..."}</Heading>
      <Text className="congrats__description" fontSize="18px">
        {isFullFinished ? "You finished the practice test!" : "Seems like you are finished practicing today?"}
      </Text>
      <Text className="congrats__sub-description" textAlign="center" w="752px" letterSpacing="0.36px">
        {isFullFinished ? (
          <>
            {session.domain ? (
              <>You answered {session.result}% of all the questions correctly.</>
            ) : (
              <>
                You answered&nbsp;
                {Object.entries(session.domain_results || {}).map(([domain, res], i, results) => (
                  <>
                    {res}% of all the questions correctly in <Text as="b">{DOMAIN_NAME[domain as DOMAIN]} Domain</Text>
                    {i + 1 < results.length && ", "}
                  </>
                ))}
                .
              </>
            )}
          </>
        ) : (
          <>
            <Text mb="16px">Here's a summary of how you're doing so far!</Text>
            {session.domain ? (
              <>You answered {session.result}% of the questions correctly.</>
            ) : (
              <>
                You answered&nbsp;
                {Object.entries(session.domain_results || {}).map(([domain, res], i, results) => (
                  <>
                    {res}%{i === 0 ? " of the questions correctly" : ""} in&nbsp;
                    <Text as="b">{DOMAIN_NAME[domain as DOMAIN]} Domain</Text>
                    {i + 1 < results.length && ", "}
                  </>
                ))}
                .
              </>
            )}
          </>
        )}
        {session.result !== null && session.result < 100 && (
          <>
            &nbsp;Take some time to review your results and see which knowledge areas need to be strengthened. Good luck
            on the exam!
            <Button
              variant="orangeText"
              ml="7px"
              p={0}
              h="min"
              onClick={viewLessonsHandler}
              rightIcon={<Next style={{ margin: "-2px 0 0 -4px" }} />}
            >
              View Lessons
            </Button>
          </>
        )}
      </Text>
      <Box className="congrats__actions">
        <Button variant="purpleBordered" onClick={viewDetailsHandler}>
          View Test Details
        </Button>
        <Button variant="purpleSolid" onClick={newTestHandler}>
          Start New Test
        </Button>
      </Box>
    </Box>
  );
};

export default Congrats;
