import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";

import { NamesOfParentRoutes, NameOfChildRoutes } from "../constants";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector(authentificated());

  if (!isAuthenticated) {
    return <Navigate to={`${NamesOfParentRoutes.AUTH}${NameOfChildRoutes.AUTH.LOGIN}`} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
