import React, { FC } from "react";
import classnames from "classnames";
import { InputProps } from "@shared/interfaces";

import "./index.scss";

export const CheckboxInput: FC<InputProps> = (props: InputProps) => {
  const { className, disabled, handleClick, ...inputProps } = props;
  return (
    <div
      className={classnames("checkbox", className, {
        "checkbox--checked": !!props.value,
        "checkbox--disabled": disabled,
      })}
    >
      <label className="checkbox__block" onClick={handleClick}>
        <span className={classnames("checkbox__checkmark", { "checkbox__checkmark--disabled": disabled })} />
        <span className="checkbox__heading">{props.label}</span>
        <input
          type="checkbox"
          className="checkbox__input"
          {...inputProps}
          disabled={disabled}
          onChange={inputProps.onChange}
          checked={!!props.value}
        />
      </label>
    </div>
  );
};
