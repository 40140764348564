import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { ButtonVariant } from "@shared/interfaces";

import { Button } from "..";

import "./index.scss";

interface PopupProps {
  header: React.ReactNode;
  content: React.ReactNode;
  confirmHandler: () => void;
  confirmText: React.ReactNode;
  cancelHandler: () => void;
  cancelText?: React.ReactNode;
  className?: string;
  confirmButtonVariant?: ButtonVariant;
}

const Popup: React.FC<PopupProps> = ({
  header,
  content,
  confirmHandler,
  confirmText,
  cancelHandler,
  cancelText = "Cancel",
  className = "",
  confirmButtonVariant,
}) => {
  return (
    <Box className="popup-overlay">
      <Box className={`popup-overlay__popup popup ${className}`}>
        <Heading as="h2" className="popup__header">
          {header}
        </Heading>
        <Box className="popup__content">{content}</Box>
        <Box className="popup__actions actions">
          {cancelHandler && (
            <Button variant="purpleText" onClick={cancelHandler}>
              {cancelText}
            </Button>
          )}
          {confirmHandler && (
            <Button variant={confirmButtonVariant || "solid"} onClick={confirmHandler} className="actions__confirm">
              {confirmText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Popup;
