import React from "react";
import { Icon } from "@shared/components";
import { Box, Flex, Text } from "@chakra-ui/react";
import "./index.scss";

export interface AuthModalProps {
  title: string;
  backClick?: () => void;
  children: React.ReactNode;
}

const AuthModal: React.FC<AuthModalProps> = (props) => {
  const { title, children, backClick } = props;

  return (
    <Box className="auth-modal">
      {backClick && (
        <Flex className="auth-modal__back" align="center" onClick={backClick}>
          <Icon type="back" />
          <Text ml={2}>Back</Text>
        </Flex>
      )}
      <Text className="auth-modal__title">{title}</Text>
      <Box className="auth-modal__content">{children}</Box>
    </Box>
  );
};

export default AuthModal;
