import { call, put, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { startLoading, stopLoading } from "@shared/store/actions";
import { Answer, Question, Session } from "@shared/models";

import { checkAnswer, finishSession, getQuestions, startSession } from "./actions";
import api from "../api";

function* startSessionSaga({ payload }: ReturnType<typeof startSession.request>) {
  try {
    yield put(startLoading());

    const params = { ...payload, guid: uuid() };
    const questions: Question[] = yield call(api.startSession, params);
    yield put(startSession.success({ ...params, result: null, domain_results: null }));
    yield put(getQuestions.success(questions));
  } catch (error) {
    yield put(startSession.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* finishSessionSaga({ payload }: ReturnType<typeof finishSession.request>) {
  try {
    yield put(startLoading());
    const session: Session = yield call(api.finishSession, payload);
    yield put(finishSession.success(session));
  } catch (error) {
    yield put(finishSession.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* checkAnswerSaga({ payload }: ReturnType<typeof checkAnswer.request>) {
  try {
    yield put(startLoading());

    const answer: Omit<Answer, "user_answers"> = yield call(api.checkAnswer, payload);
    yield put(checkAnswer.success({ ...answer, user_answers: payload.options }));
  } catch (error) {
    yield put(checkAnswer.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getQuestionsSaga({ payload }: ReturnType<typeof getQuestions.request>) {
  try {
    const questions: Question[] = yield call(api.getQuestions, payload);
    yield put(getQuestions.success(questions));
  } catch (error) {
    yield put(getQuestions.failure(error as Error));
  }
}

function* testSagas() {
  yield takeLatest(startSession.request, startSessionSaga);
  yield takeLatest(finishSession.request, finishSessionSaga);
  yield takeLatest(checkAnswer.request, checkAnswerSaga);
  yield takeLatest(getQuestions.request, getQuestionsSaga);
}

export default testSagas;
