import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "@shared/store/selectors";

import { RegistrationForm, AuthModal } from "../../components";
import { RegistrationShape } from "../../interface";
import { actions } from "../../store";

const RegistrationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading());

  const handleSubmit = useCallback(
    (values: RegistrationShape) => {
      dispatch(actions.registration.request(values));
    },
    [dispatch],
  );

  return (
    <AuthModal title="Create Account">
      <RegistrationForm loading={loading} submitHandler={handleSubmit} />
    </AuthModal>
  );
};

export default RegistrationContainer;
