import React, { Suspense, ExoticComponent } from "react";
import { Spinner } from "@chakra-ui/react";

const Loadable = (Component: ExoticComponent) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<Spinner color="blue.500" />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
