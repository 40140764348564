import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { Box, Divider, Text, Link } from "@chakra-ui/react";
import { Answer, Question as QuestionModel } from "@shared/models";
import { Radio, Checkbox } from "@shared/components";
import { CheckboxInputOptionProps, CheckboxVariant, RadioInputOptionProps, RadioVariant } from "@shared/interfaces";
import { DOMAIN_NAME } from "@shared/constants";

import "./index.scss";

interface QuestionProps {
  question: QuestionModel;
  answer: Answer;
  singleQuestion?: boolean;
}
const QuestionFeedback: FC<QuestionProps> = ({ question, answer, singleQuestion = false }) => {
  const [values, setValues] = useState<string[]>([]);
  const options = useMemo<Array<RadioInputOptionProps | CheckboxInputOptionProps>>(
    () =>
      [...question.options]
        .sort((a, b) => a.order - b.order)
        .map((option) => {
          let variant: CheckboxVariant | RadioVariant | null = null;
          const correctAnswer = answer?.correct_answers.includes(option.id);
          const chosenAsAnswer = answer?.user_answers.includes(option.id);
          if (correctAnswer) {
            variant = chosenAsAnswer ? "rightChecked" : "rightUnchecked";
          } else if (chosenAsAnswer) {
            variant = "wrongChecked";
          }

          return {
            value: String(option.id),
            label: `${option.value}${
              chosenAsAnswer || correctAnswer ? ` (${correctAnswer ? "Correct" : "Incorrect"})` : ""
            }`,
            isDisabled: true,
            variant: variant || undefined,
          };
        }),
    [answer, question.options],
  );

  useEffect(() => {
    setValues(answer?.user_answers?.map(String) || []);
  }, [answer]);

  return (
    <Box className={`question-feedback ${singleQuestion ? "question-feedback--single-question" : ""}`}>
      <Box className="question-feedback__text">
        {question.stem.split("\n").map((part, i) => (
          <Fragment key={i}>
            {part}
            <br />
          </Fragment>
        ))}
      </Box>

      {question.is_multiple ? (
        <Checkbox options={options} values={values} className="question-feedback__options" />
      ) : (
        <Radio options={options} value={values[0]} className="question-feedback__options" />
      )}
      {singleQuestion && <Divider borderColor="#cbd5e0" my="40px" className="question-feedback__divider" />}
      <Box className="question-feedback__description description">
        <Box className="description_block block block--explanation">
          <Text className="block__title">Explanation:</Text>
          <Text className="block__text">{question.feedback}</Text>
        </Box>
        <Box className="description_block block">
          <Text className="block__title">Domain:</Text>
          <Text className="block__text">
            {question.references
              .filter(({ type }) => type === "domain")
              .map((reference, i, ref) => {
                const text = `${reference.domain ? `${DOMAIN_NAME[reference.domain]}, ` : ""}${
                  reference.reference_number
                } ${reference.title}`;
                return (
                  <Fragment key={reference.id}>
                    {reference.link ? (
                      <Link href={reference.link} isExternal target="_blank">
                        {text}
                      </Link>
                    ) : (
                      text
                    )}
                    {i + 1 < ref.length && ", "}
                  </Fragment>
                );
              })}
          </Text>
        </Box>
        <Box className="description_block block">
          <Text className="block__title">eLearning Module:</Text>
          <Text className="block__text">
            {question.references
              .filter(({ type }) => type === "elearning")
              .map((reference, i, ref) => {
                const text = `${reference.reference_number} ${reference.title}`;
                return (
                  <Fragment key={reference.id}>
                    {reference.link ? (
                      <Link href={reference.link} isExternal target="_blank">
                        {text}
                      </Link>
                    ) : (
                      text
                    )}
                    {i + 1 < ref.length && ", "}
                  </Fragment>
                );
              })}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionFeedback;
