export enum FieldItemType {
  PASSWORD = "password",
  EMAIL = "email",
  NUMBER = "number",
  TEXT = "text",
  CHECKBOX = "checkbox",
  SELECT = "select",
  MULTI_SELECT = "multi-select",
  TEXTAREA = "textarea",
  RICH_TEXT = "rich_textarea",
  RENDER = "render",
  BLOCK = "block",
  AUTOCOMPLETE_SELECT = "autocomplete_select",
  AUTOCOMPLETE_SELECT_MULTI = "autocomplete_select_multi",
  AUTOCOMPLETE_CHECKBOX_SELECT_MULTI = "autocomplete_checkbox_select_multi",
  AUTOCOMPLETE_INPUT = "autocomplete_input",
  OPTIONS_SELECTOR = "options_selector",
  TIMEPICKER = "timepicker",
  TIMEPICKER_SWITCHER = "timepicker_switcher",
  DATEPICKER = "datepicker",
  FILE_BUTTON = "file_button",
}
