import React, { useMemo } from "react";
import { Select } from "@shared/components";
import { FieldItemType, Option } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface SelectFieldItem extends RenderField {
  type: FieldItemType.SELECT;
  label?: string;
  options: Option<string>[];
}

export const GenerateSelect: GenerateRenderField<SelectFieldItem> = ({ formikProps, options, name, wrapperClass }) => {
  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === formikProps.values[name]) || null;
  }, [options, name, formikProps.values]);

  return (
    <Select
      name={name}
      options={options}
      value={currentOptionValue}
      className={wrapperClass}
      onChange={(option: Option<string> | null) => formikProps.setFieldValue(name, option?.value || null)}
    />
  );
};
