import React, { FC } from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { DOMAIN, DOMAIN_NAME } from "@shared/constants";

import "./index.scss";

interface LessonsProps {
  lessons: Partial<Record<DOMAIN, string[]>>;
}
const Lessons: FC<LessonsProps> = ({ lessons }) => {
  return (
    <Box overflowY="auto" h="full" className="lessons-to-learn" pb="16px" pt="16px">
      <Text>Areas that you should focus on to improve your scores:</Text>
      <VStack alignItems="flex-start" spacing="40px" mt="32px">
        {Object.entries(lessons).map(([domain, eLearnings]) =>
          eLearnings.length > 0 ? (
            <Box key={domain}>
              <Heading
                as="h4"
                fontFamily="pressura"
                color="#718096"
                fontSize="14px"
                fontWeight="400"
                letterSpacing="1.4px"
                textTransform="uppercase"
              >
                {DOMAIN_NAME[domain as DOMAIN]}
              </Heading>
              <VStack alignItems="flex-start" m="16px 0 0 32px" spacing="16px">
                {eLearnings.map((eLearning) => (
                  <Text key={eLearning}>{eLearning}</Text>
                ))}
              </VStack>
            </Box>
          ) : null,
        )}
      </VStack>
    </Box>
  );
};

export default Lessons;
