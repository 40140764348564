import { Middleware, Dispatch } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";

import appSagas from "./saga";
import rootReducer from "./reducer";

const sagaMiddleware = createSagaMiddleware();
let middleware: Array<Middleware>;

const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action) => {
  if (action.type.includes("FAILURE")) {
    // next(
    //   showNotification({
    //     message: action.payload.error || action.payload.message,
    //     appearance: "error",
    //   }),
    // );

    if (action.payload && (action.payload.code === 401 || action.payload.code === 403)) {
      localStorage.clear();
    }
  }

  if (action.type.includes("SUCCESS") && action.payload && action.payload.message) {
    // next(
    //   showNotification({
    //     message: action.payload.message,
    //     appearance: "success",
    //   }),
    // );
  }

  return next(action);
};

if (process.env.NODE_ENV === "development") {
  middleware = [freeze, sagaMiddleware, errorHandlerMiddleware];
} else {
  middleware = [sagaMiddleware, errorHandlerMiddleware];
}

export default function createStore() {
  const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
  });
  sagaMiddleware.run(appSagas);

  return { store };
}
