import { ChangePasswordShape } from "@containers/Auth/interface";
import { FieldItem } from "@shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { FieldItemType } from "@shared/interfaces";
import * as Yup from "yup";
import { IS_EXIST_DIGIT, IS_EXIST_LOWERCASE, IS_EXIST_UPPERCASE } from "@shared/constants/regExMatches";

export const validationSchema: Yup.Schema<ChangePasswordShape> = Yup.object().shape({
  password: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  confirm_password: Yup.string()
    .required(FORM_ERROR_MESSAGES.REQUIRED)
    .min(6, `Password ${FORM_ERROR_MESSAGES.SHORT}`)
    .matches(IS_EXIST_DIGIT, FORM_ERROR_MESSAGES.DIGIT)
    .matches(IS_EXIST_LOWERCASE, FORM_ERROR_MESSAGES.LOWERCASE)
    .matches(IS_EXIST_UPPERCASE, FORM_ERROR_MESSAGES.UPPERCASE)
    .oneOf([Yup.ref("password")], FORM_ERROR_MESSAGES.PASSWORDS_MATCH),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.PASSWORD,
    name: "password",
    label: "Password",
    wrapperClass: "input-line",
    isShowPasswordIcon: true,
  },
  {
    type: FieldItemType.PASSWORD,
    name: "confirm_password",
    label: "Confirm Password",
    wrapperClass: "input-line",
    isShowPasswordIcon: true,
  },
];

export const initValues: ChangePasswordShape = {
  password: "",
  confirm_password: "",
};
