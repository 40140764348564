import React, { useState, FC } from "react";
import { Formik } from "formik";
import { Box, Image, Heading, Text } from "@chakra-ui/react";
import { WelcomeShape } from "@containers/Test/interface";
import { FormProps } from "@shared/interfaces";
import { FormGenerator, Button } from "@shared/components";
import logo from "@assets/files/images/general/logo.png";
import { ReactComponent as Next } from "@assets/files/icons/general/next.svg";

import { validationSchema, fields, initValues } from "./formHelpers";
import "./index.scss";

const WelcomeForm: FC<FormProps<WelcomeShape>> = ({ loading, submitHandler }) => {
  const [formValues] = useState(initValues);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (submitHandler) {
          submitHandler(values);
        }
      }}
      initialValues={formValues}
    >
      {(formikProps) => (
        <Box className="welcome-form">
          <Image src={logo} alt="Logo" className="welcome-form__logo" />
          <Heading className="welcome-form__title" textAlign="center" fontSize="26px" lineHeight="24px">
            Welcome to the PMI Authorized
            <br />
            PMP Practice Exam.
          </Heading>
          <Text className="welcome-form__description">
            This practice exam is designed using adaptive learning.
            <br />
            This will allow you, the student, to be asked questions based
            <br />
            on your skill level as well as provide you with a list of modules
            <br />
            for the PMI Authorized On Demand PMP Prep Course
            <br />
            to focus on to improve your scores.
          </Text>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button
              className="welcome-form__submit"
              type="submit"
              variant="purpleSolid"
              isDisabled={loading || !validationSchema.isValidSync(formikProps.values)}
              rightIcon={<Next />}
            >
              Next
            </Button>
          </FormGenerator>
        </Box>
      )}
    </Formik>
  );
};

export default WelcomeForm;
