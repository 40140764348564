import { createAction, createAsyncAction } from "typesafe-actions";
import { ResponseError } from "@shared/interfaces";
import { Answer, Question, Session } from "@shared/models";

import { TestActionTypes } from "./constants";
import { CheckAnswer, StartSession, TestError } from "../interface";

export const startSession = createAsyncAction(
  TestActionTypes.START_SESSION,
  TestActionTypes.START_SESSION_SUCCESS,
  TestActionTypes.START_SESSION_FAILURE,
  TestActionTypes.START_SESSION_CANCEL,
)<StartSession, Session, ResponseError, void>();

export const finishSession = createAsyncAction(
  TestActionTypes.FINISH_SESSION,
  TestActionTypes.FINISH_SESSION_SUCCESS,
  TestActionTypes.FINISH_SESSION_FAILURE,
)<string, Session | null, ResponseError>();

export const getQuestions = createAsyncAction(
  TestActionTypes.GET_QUESTIONS,
  TestActionTypes.GET_QUESTIONS_SUCCESS,
  TestActionTypes.GET_QUESTIONS_FAILURE,
  TestActionTypes.GET_QUESTIONS_CANCEL,
)<string, Question[], ResponseError, void>();

export const checkAnswer = createAsyncAction(
  TestActionTypes.CHECK_ANSWER,
  TestActionTypes.CHECK_ANSWER_SUCCESS,
  TestActionTypes.CHECK_ANSWER_FAILURE,
)<CheckAnswer, Answer, ResponseError>();

export const clearAnswers = createAction(TestActionTypes.CLEAR_ANSWERS)();

export const clearErrors = createAction(TestActionTypes.CLEAR_ERRORS)<TestError[] | void>();
