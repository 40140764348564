import { Configuration, ConfigurationObject } from "@shared/interfaces/Configuration";

const { REACT_APP_ENV = "dev" } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:4000/api/v1/",
};

const qa: Configuration = {
  ...dev,
  baseApiUrl: "https://api.qa.adaptivetest.ninetwothree.co/api/v1/",
};

const uat: Configuration = {
  ...dev,
  baseApiUrl: "https://api.uat.adaptivetest.ninetwothree.co/api/v1/",
};

const production: Configuration = {
  ...dev,
};

const config: ConfigurationObject = {
  dev,
  qa,
  uat,
  production,
};

const configElement: Configuration = config[REACT_APP_ENV];
export default configElement;
