import React, { FC } from "react";
import { CheckboxGroup, Checkbox as Option, Stack } from "@chakra-ui/react";
import { CheckboxInputProps } from "@shared/interfaces";

import "./index.scss";

export const Checkbox: FC<CheckboxInputProps> = ({ onChange, options, values, direction = "column", className }) => {
  return (
    <CheckboxGroup onChange={onChange} value={values}>
      <Stack direction={direction} className={`input-checkbox ${className || ""}`}>
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            className={option.className || ""}
            isDisabled={option.isDisabled}
            variant={option.variant}
            size="lg"
          >
            {option.label}
          </Option>
        ))}
      </Stack>
    </CheckboxGroup>
  );
};
