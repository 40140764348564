import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { selectAnswers, selectQuestions, selectSession } from "@containers/Test/store/selectors";
import { DOMAIN, NameOfChildRoutes, NameOfRoutes } from "@shared/constants";
import { clearAnswers, clearErrors, getQuestions, startSession } from "@containers/Test/store/actions";
import { TEST_QUESTIONS_NUMBER } from "@containers/Test/constants";
import { Button, Overlay } from "@shared/components";
import { Reference } from "@shared/models";
import { ReactComponent as Previous } from "@assets/files/icons/general/previous.svg";

import { Congrats, TestResults, Lessons } from "../../components";

import "./index.scss";

const ResultsContainer: React.FC = () => {
  const [testDetails, setTestDetails] = useState(false);
  const [lessonsIsShown, setLessonsIsShown] = useState(false);
  const session = useSelector(selectSession());
  const questions = useSelector(selectQuestions());
  const answers = useSelector(selectAnswers());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!session) {
      navigate(`${NameOfRoutes.TEST}${NameOfChildRoutes.TEST.WELCOME}`);
    } else if (!session.is_finished) {
      navigate(`${NameOfRoutes.TEST}${NameOfChildRoutes.TEST.QUESTIONS}`);
    }
  }, [navigate, session]);

  const lessons = useMemo(() => {
    const eLearnings: Partial<Record<DOMAIN, string[]>> = {};
    const eLearningReferences: Record<DOMAIN, Reference[]> = {
      [DOMAIN.PEOPLE]: [],
      [DOMAIN.PROCESS]: [],
      [DOMAIN.BUSINESS_ENV]: [],
    };
    if (questions && answers) {
      questions.forEach(({ id, references }) => {
        if (answers[id]?.is_correct === false) {
          references.forEach((ref) => {
            if (ref.type === "elearning") {
              eLearningReferences[ref.domain].push(ref);
            }
          });
        }
      });
      Object.entries(eLearningReferences).forEach(([domain, learnings]) => {
        learnings.sort((a, b) => {
          const [, subNumber1] = a.reference_number.split(".");
          const [, subNumber2] = b.reference_number.split(".");
          return Number(subNumber1) - Number(subNumber2);
        });
        const titles = learnings.map(({ reference_number, title }) => `${reference_number} ${title}`);
        eLearnings[domain as DOMAIN] = [...new Set(titles)];
      });
    }

    return eLearnings;
  }, [answers, questions]);

  const handleNewTest = useCallback(() => {
    dispatch(startSession.cancel());
    dispatch(getQuestions.cancel());
    dispatch(clearAnswers());
    dispatch(clearErrors());
  }, [dispatch]);

  const handleViewDetails = useCallback(() => {
    setTestDetails(true);
  }, []);

  const closeLessonsHandler = useCallback(() => {
    setLessonsIsShown(false);
  }, []);

  const openLessonsHandler = useCallback(() => {
    setLessonsIsShown(true);
  }, []);

  return session && questions && answers ? (
    <Box className="results-container">
      <Overlay header="eLearning Lessons" width="504px" isOpen={lessonsIsShown} onClose={closeLessonsHandler}>
        <Lessons lessons={lessons} />
      </Overlay>
      {testDetails ? (
        <>
          <Box className="results-container__header">
            <Button
              variant="purpleText"
              className="actions__previous"
              leftIcon={<Previous />}
              onClick={() => setTestDetails(false)}
            >
              Go Back
            </Button>
            <Button variant="purpleSolid" onClick={handleNewTest}>
              Start New Test
            </Button>
          </Box>
          <Box className="results-container__content">
            <TestResults questions={questions} answers={answers} />
          </Box>
        </>
      ) : (
        <Congrats
          session={session}
          isFullFinished={session.answered_questions === TEST_QUESTIONS_NUMBER}
          newTestHandler={handleNewTest}
          viewDetailsHandler={handleViewDetails}
          viewLessonsHandler={openLessonsHandler}
        />
      )}
      <Box className="results-container__figures" />
    </Box>
  ) : null;
};

export default ResultsContainer;
