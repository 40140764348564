import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { radioAnatomy, checkboxAnatomy } from "@chakra-ui/anatomy";

import configureStore from "./store";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";

import "@assets/styles/index.scss";

const { store } = configureStore();

if (process.env.REACT_APP_ENV === "dev") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
}

const { definePartsStyle: defineRadioStyles } = createMultiStyleConfigHelpers(radioAnatomy.keys);
const { definePartsStyle: defineCheckboxStyles } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const styles = {
  styles: {
    global: {
      body: {
        color: "#212121",
      },
    },
  },
  colors: {
    brand: {
      "900": "#1a365d",
      "800": "#0047b3",
      "700": "#0080ff",
      "600": "#00b4ff",
      "500": "#00edff",
      "400": "#00ffcc",
      "300": "#00ff99",
      "200": "#00ff66",
      "100": "#00ff33",
    },
    orange: {
      "450": "#ff610f",
    },
    purple: {
      "650": "#4f17a8",
    },
    red: {
      "450": "#e12828",
    },
    green: {
      "750": "#0c884c",
    },
  },
  fonts: {
    body: "Agrandir, sans-serif",
    heading: "Agrandir, sans-serif",
    pressura: "GT Pressura Mono, sans-serif",
  },
  components: {
    Input: {
      disabled: {
        opacity: 0.6,
      },
    },
    Progress: {
      baseStyle: {
        track: {
          bg: "gray.300",
        },
        filledTrack: {
          bg: "purple.650",
        },
      },
    },
    Button: {
      variants: {
        solid: {
          backgroundColor: "orange.450",
          color: "white",
          _disabled: {
            backgroundColor: "gray.300",
            opacity: 1,
          },
          _hover: {
            backgroundColor: "orange.450",
            _disabled: {
              backgroundColor: "gray.300",
              opacity: 1,
            },
          },
          _active: {
            backgroundColor: "orange.450",
          },
        },
        purpleSolid: {
          backgroundColor: "purple.650",
          color: "white",
          _disabled: {
            backgroundColor: "gray.300",
            opacity: 1,
          },
          _hover: {
            _disabled: {
              backgroundColor: "gray.300",
              opacity: 1,
            },
          },
        },
        purpleText: {
          color: "purple.650",
        },
        purpleBordered: {
          color: "purple.650",
          border: "1px solid",
          borderColor: "purple.650",
        },
        orangeText: {
          color: "orange.450",
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            borderColor: "orange.450",
            backgroundColor: "orange.450",
            _hover: {
              borderColor: "orange.450",
              backgroundColor: "orange.450",
            },
          },
          _disabled: {
            borderColor: "gray.300",
            backgroundColor: "white",
            opacity: 1,
          },
        },
        label: {
          _disabled: {
            color: "gray.500",
            opacity: 1,
          },
        },
      },
      variants: {
        wrongChecked: defineRadioStyles({
          control: {
            _checked: {
              _disabled: {
                borderColor: "red.450",
                backgroundColor: "red.450",
                color: "white",
              },
            },
          },
          label: {
            _checked: {
              color: "red.450",
            },
          },
        }),
        rightUnchecked: defineRadioStyles({
          control: {
            _disabled: {
              borderColor: "green.750",
            },
          },
          label: {
            _disabled: {
              color: "green.750",
            },
          },
        }),
        rightChecked: defineRadioStyles({
          control: {
            _checked: {
              _disabled: {
                borderColor: "green.750",
                backgroundColor: "green.750",
                color: "white",
              },
            },
          },
          label: {
            _checked: {
              _disabled: {
                color: "green.750",
              },
            },
          },
        }),
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          color: "white",
          _checked: {
            borderColor: "orange.450",
            backgroundColor: "orange.450",
            _hover: {
              borderColor: "orange.450",
              backgroundColor: "orange.450",
            },
          },
          _disabled: {
            borderColor: "gray.300",
            backgroundColor: "white",
            opacity: 1,
          },
        },
        label: {
          _disabled: {
            color: "gray.500",
            opacity: 1,
          },
        },
        icon: {
          height: "8px",
        },
      },
      variants: {
        wrongChecked: defineCheckboxStyles({
          control: {
            _checked: {
              _disabled: {
                borderColor: "red.450",
                backgroundColor: "red.450",
                color: "white",
              },
            },
          },
          label: {
            _checked: {
              _disabled: {
                color: "red.450",
              },
            },
          },
        }),
        rightUnchecked: defineCheckboxStyles({
          control: {
            _disabled: {
              borderColor: "green.750",
            },
          },
          label: {
            _disabled: {
              color: "green.750",
            },
          },
        }),
        rightChecked: defineCheckboxStyles({
          control: {
            _checked: {
              _disabled: {
                borderColor: "green.750",
                backgroundColor: "green.750",
                color: "white",
              },
            },
          },
          label: {
            _checked: {
              _disabled: {
                color: "green.750",
              },
            },
          },
        }),
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "#cbd5e0",
        opacity: 1,
      },
    },
  },
};

const theme = extendTheme(styles);

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
