import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { Answer, Question } from "@shared/models";
import { QuestionFeedback } from "@containers/Test/components";

import "./index.scss";

interface TestResultsProps {
  questions: Question[];
  answers: Record<number, Answer>;
}
const TestResults: FC<TestResultsProps> = ({ questions, answers }) => {
  return (
    <Box className="test-results">
      {questions
        .filter(({ id }) => answers[id])
        .map((question) => (
          <Box key={question.id} className="test-results__question-feedback">
            <QuestionFeedback question={question} answer={answers[question.id]} />
          </Box>
        ))}
    </Box>
  );
};

export default TestResults;
