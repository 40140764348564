import React, { lazy, FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Loadable } from "@shared/components";
import { NameOfChildRoutes } from "@shared/constants";
import "./index.scss";
import { GuestGuard } from "@shared/guards";

const LoginContainer = Loadable(lazy(() => import("../LoginContainer/LoginContainer")));
const RestoreContainer = Loadable(lazy(() => import("../RestoreContainer/RestoreContainer")));
const ChangePasswordContainer = Loadable(lazy(() => import("../ChangePasswordContainer/ChangePasswordContainer")));
const RegistrationContainer = Loadable(lazy(() => import("../RegistrationContainer/RegistrationContainer")));

const routes = [
  {
    path: `${NameOfChildRoutes.AUTH.LOGIN}`,
    default: true,
    element: (
      <GuestGuard>
        <LoginContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.FORGOT_PASSWORD}`,
    element: (
      <GuestGuard>
        <RestoreContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.CHANGE_PASSWORD}/:hash`,
    element: (
      <GuestGuard>
        <ChangePasswordContainer />,
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.REGISTRATION}`,
    element: (
      <GuestGuard>
        <RegistrationContainer />,
      </GuestGuard>
    ),
  },
];

const AuthContainer: FC = () => {
  const content = useRoutes(routes);
  return <Box className="auth-container">{content}</Box>;
};

export default AuthContainer;
