import React, { FC } from "react";
import { Box, Progress as ProgressBar, Text } from "@chakra-ui/react";

import "./index.scss";

interface ProgressProps {
  totalCount: number;
  passedCount: number;
}
const Progress: FC<ProgressProps> = ({ totalCount, passedCount }) => {
  return (
    <Box className="progress">
      <Text className="progress__title">
        Question&nbsp;
        <Text as="b">
          {passedCount}/{totalCount}
        </Text>
      </Text>
      <ProgressBar value={100 * (passedCount / totalCount)} height="6px" />
    </Box>
  );
};

export default Progress;
