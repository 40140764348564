import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { SharedStateType, LoadingSections } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: SharedStateType = {
  loading: false,
  notification: null,
  modal: {},
  overlay: {},
  navigateURL: "",
  location: null,
  loadingSections: {},
  loadingTypes: [],
};

const reducer = createReducer<SharedStateType, Action>(initialState)
  .handleAction(actions.startLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = true;
    }),
  )
  .handleAction(actions.stopLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = false;
    }),
  )

  .handleAction(actions.showOverlay, (state, action) =>
    produce(state, (nextState) => {
      nextState.overlay = { show: true, ...action.payload };
    }),
  )
  .handleAction(actions.showModal, (state, action) =>
    produce(state, (nextState) => {
      nextState.modal = { show: true, ...action.payload };
    }),
  )
  .handleAction(actions.hideOverlay, (state) =>
    produce(state, (nextState) => {
      nextState.overlay = {};
    }),
  )
  .handleAction(actions.hideModal, (state) =>
    produce(state, (nextState) => {
      nextState.modal = {};
    }),
  )
  .handleAction(actions.showNotification, (state, action) =>
    produce(state, (nexState) => {
      nexState.notification = action.payload;
    }),
  )
  .handleAction(actions.navigate, (state, action) =>
    produce(state, (nexState) => {
      nexState.navigateURL = action.payload;
    }),
  )
  .handleAction(actions.addLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = [...state.loadingTypes, action.payload];
    }),
  )
  .handleAction(actions.removeLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = state.loadingTypes.filter(({ name }) => name !== action.payload);
      nextState.loadingSections = {};
    }),
  )
  .handleAction(actions.addLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections: LoadingSections = { ...state.loadingSections };

      if (!loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = [];
      }

      loadingSections[action.payload.loadingSection] = [
        ...Array.from(new Set([...loadingSections[action.payload.loadingSection], action.payload.requestName])),
      ];

      nextState.loadingSections = loadingSections;
    }),
  )
  .handleAction(actions.removeLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections = { ...state.loadingSections };

      if (loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = loadingSections[action.payload.loadingSection].filter(
          (loadingRequest) => loadingRequest !== action.payload.requestName,
        );

        if (!loadingSections[action.payload.loadingSection].length) {
          delete loadingSections[action.payload.loadingSection];
        }

        nextState.loadingSections = loadingSections;
      }
    }),
  );

export { reducer as SharedReducer };
