import React, { useCallback, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAnswers, selectQuestions, selectSession } from "@containers/Test/store/selectors";
import { NameOfChildRoutes, NameOfRoutes } from "@shared/constants";
import { checkAnswer, clearErrors, finishSession, getQuestions, startSession } from "@containers/Test/store/actions";
import { TEST_QUESTIONS_NUMBER } from "@containers/Test/constants";
import { Popup } from "@shared/components";

import { Question, Progress } from "../../components";

import "./index.scss";

const QuestionsContainer: React.FC = () => {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [isFinishPopupShown, setIsFinishPopupShown] = useState(false);
  const [isStartNewPopupShown, setIsStartNewPopupShown] = useState(false);

  const session = useSelector(selectSession());
  const questions = useSelector(selectQuestions());
  const answers = useSelector(selectAnswers());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!session) {
      navigate(`${NameOfRoutes.TEST}${NameOfChildRoutes.TEST.WELCOME}`);
    } else if (session.is_finished) {
      navigate(`${NameOfRoutes.TEST}${NameOfChildRoutes.TEST.RESULTS}`);
    }
  }, [navigate, session]);

  useEffect(() => {
    if (
      session &&
      questions &&
      answers &&
      questions.length < TEST_QUESTIONS_NUMBER &&
      Object.keys(answers).length === questions.length
    ) {
      dispatch(getQuestions.request(session.guid));
    }
  }, [answers, dispatch, questionNumber, questions, session]);

  const handleFinish = useCallback(() => {
    if (session) {
      dispatch(finishSession.request(session.guid));
      setIsFinishPopupShown(false);
    }
  }, [dispatch, session]);

  const handleSubmit = useCallback(
    (values: string[]) => {
      if (session && questions?.[questionNumber]) {
        if (!answers?.[questions[questionNumber].id]) {
          dispatch(
            checkAnswer.request({
              session_guid: session.guid,
              question_id: questions[questionNumber].id,
              options: values.map(Number),
            }),
          );
        } else if (questionNumber + 1 < TEST_QUESTIONS_NUMBER) {
          setQuestionNumber(questionNumber + 1);
        } else {
          handleFinish();
        }
      }
    },
    [answers, dispatch, handleFinish, questionNumber, questions, session],
  );

  const handlePreviousQuestion = useCallback(() => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
    }
  }, [questionNumber]);

  const cancelFinishAndQuit = useCallback(() => {
    setIsFinishPopupShown(false);
  }, []);

  const handleFinishAndQuit = useCallback(() => {
    if (answers && Object.keys(answers).length === TEST_QUESTIONS_NUMBER) {
      handleFinish();
    } else {
      setIsFinishPopupShown(true);
    }
  }, [answers, handleFinish]);

  const handleStartNewTest = useCallback(() => {
    setIsStartNewPopupShown(true);
  }, []);

  const cancelStartNewTest = useCallback(() => {
    setIsStartNewPopupShown(false);
  }, []);

  const submitStartNewTest = useCallback(() => {
    dispatch(startSession.cancel());
    dispatch(getQuestions.cancel());
    dispatch(clearErrors());
  }, [dispatch]);

  return questions ? (
    <Box className="questions-container">
      {isFinishPopupShown && (
        <Popup
          header="Are you sure you want to quit now?"
          content="Your results will not be final. And you will not be able to resume the test later."
          cancelHandler={cancelFinishAndQuit}
          confirmText="Finish & Quit"
          confirmHandler={handleFinish}
        />
      )}
      {isStartNewPopupShown && (
        <Popup
          header="Are you sure you want to start new test?"
          content=""
          cancelHandler={cancelStartNewTest}
          confirmText="Start New Test"
          confirmHandler={submitStartNewTest}
        />
      )}
      {/* @TODO: request questions count from server */}
      <Progress totalCount={TEST_QUESTIONS_NUMBER} passedCount={questionNumber + 1} />
      <Question
        question={questions[questionNumber]}
        isLast={questionNumber + 1 === TEST_QUESTIONS_NUMBER}
        submitHandler={handleSubmit}
        answer={answers?.[questions[questionNumber].id]}
        previousQuestionHandler={questionNumber > 0 ? handlePreviousQuestion : undefined}
        finishAndQuitHandler={handleFinishAndQuit}
        allQuestionsAnswered={!!answers && Object.keys(answers).length === TEST_QUESTIONS_NUMBER}
        startNewTestHandler={questionNumber === 0 && answers === null ? handleStartNewTest : undefined}
      />
      <Text mt={10} mb="auto" color="gray.500" fontSize={12}>
        Remember, this is a practice test, not a simulation – Only some questions are in PMP® Certification exam format!
      </Text>
      <Box className="questions-container__figures" />
    </Box>
  ) : null;
};

export default QuestionsContainer;
