import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState, FC } from "react";
import { Field } from "formik";

import "./index.scss";

interface InputProps extends ChakraInputProps {
  label?: string;
  isShowPasswordIcon?: boolean;
  isRequiredField?: boolean;
  helpText?: string;
}

const Input: FC<InputProps> = ({ label, isShowPasswordIcon, isRequiredField, helpText, ...inputProps }) => {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <FormControl isRequired={isRequiredField} mt={4} className="input">
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Field
          as={ChakraInput}
          {...inputProps}
          _focusVisible={{ boxShadow: "transparent" }}
          type={isShowPassword ? "text" : inputProps.type}
        />
        {isShowPasswordIcon && (
          <InputRightElement>
            <IconButton
              aria-label={isShowPassword ? "Hide password" : "Show password"}
              icon={isShowPassword ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => setShowPassword(!isShowPassword)}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
