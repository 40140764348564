import React, { lazy } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { NameOfChildRoutes } from "@shared/constants";
import { Loadable, NotFound } from "@shared/components";

import "./index.scss";

const WelcomeContainer = Loadable(lazy(() => import("../WelcomeContainer/WelcomeContainer")));
const QuestionsContainer = Loadable(lazy(() => import("../QuestionsContainer/QuestionsContainer")));
const ResultsContainer = Loadable(lazy(() => import("../ResultsContainer/ResultsContainer")));

const routes: RouteObject[] = [
  {
    path: NameOfChildRoutes.TEST.WELCOME,
    element: <WelcomeContainer />,
  },
  {
    path: NameOfChildRoutes.TEST.RESULTS,
    element: <ResultsContainer />,
  },
  {
    path: NameOfChildRoutes.TEST.QUESTIONS,
    element: <QuestionsContainer />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const TestContainer: React.FC = () => {
  const content = useRoutes(routes);
  return <Box className="test-container">{content}</Box>;
};

export default TestContainer;
