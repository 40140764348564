import { RestoreShape } from "@containers/Auth/interface";
import { FieldItem } from "@shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { FieldItemType } from "@shared/interfaces";
import * as Yup from "yup";

export const validationSchema: Yup.Schema<RestoreShape> = Yup.object().shape({
  email: Yup.string().email(FORM_ERROR_MESSAGES.EMAIL).required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "email",
    label: "Email",
    wrapperClass: "input-line",
  },
];

const initValues: RestoreShape = {
  email: "",
};

export const getInitValues = (email: string | null) => {
  return email ? { email } : initValues;
};
