import { ResponseError } from "@shared/interfaces";
import { Question, Answer, Session } from "@shared/models";

export enum TestError {
  startSession = "startSession",
  finishSession = "finishSession",
  checkAnswer = "checkAnswer",
  getQuestions = "getQuestions",
}

export interface TestStateType {
  session: Session | null;
  questions: Question[] | null;
  answers: Record<number, Answer> | null;
  errors: {
    [key in TestError]: ResponseError | null;
  };
}
