import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { TestError, TestStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: TestStateType = {
  session: null,
  questions: null,
  answers: null,
  errors: {
    [TestError.startSession]: null,
    [TestError.checkAnswer]: null,
    [TestError.getQuestions]: null,
    [TestError.finishSession]: null,
  },
};

const reducer = createReducer<TestStateType, Action>(initialState)
  .handleAction(actions.startSession.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.session = action.payload;
      nextState.errors[TestError.startSession] = null;
    }),
  )
  .handleAction(actions.startSession.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[TestError.startSession] = action.payload;
    }),
  )
  .handleAction(actions.startSession.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.session = null;
      nextState.errors[TestError.startSession] = null;
    }),
  )
  .handleAction(actions.finishSession.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.session = action.payload;
      nextState.errors[TestError.finishSession] = null;
    }),
  )
  .handleAction(actions.finishSession.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[TestError.finishSession] = action.payload;
    }),
  )
  .handleAction(actions.getQuestions.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.questions = Array.isArray(nextState.questions)
        ? [...nextState.questions, ...action.payload]
        : action.payload;
    }),
  )
  .handleAction(actions.getQuestions.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[TestError.getQuestions] = action.payload;
    }),
  )
  .handleAction(actions.getQuestions.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.questions = null;
      nextState.errors[TestError.getQuestions] = null;
    }),
  )
  .handleAction(actions.checkAnswer.success, (state, action) =>
    produce(state, (nextState) => {
      if (!nextState.answers) {
        nextState.answers = {};
      }
      nextState.answers[action.payload.question_id] = action.payload;
      nextState.errors[TestError.checkAnswer] = null;
    }),
  )
  .handleAction(actions.checkAnswer.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[TestError.checkAnswer] = action.payload;
    }),
  )
  .handleAction(actions.clearAnswers, (state) =>
    produce(state, (nextState) => {
      nextState.answers = null;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export default reducer;
