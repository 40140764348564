import { all, fork } from "redux-saga/effects";

import { saga as authSaga } from "../containers/Auth/store";
import { saga as testSaga } from "../containers/Test/store";

const allSagas = [authSaga, testSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
