export const ACCEPT_FILE_TYPES = ".doc, .docx, .jpg, .jpeg, .psd, .pdf, .txt, .xls, .xlsx, .png";

export enum DOMAIN {
  PEOPLE = "people",
  PROCESS = "process",
  BUSINESS_ENV = "business_environment",
}

export const DOMAIN_NAME: Record<DOMAIN, string> = {
  [DOMAIN.PEOPLE]: "People",
  [DOMAIN.PROCESS]: "Process",
  [DOMAIN.BUSINESS_ENV]: "Business environment",
};
