import React from "react";
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";

interface OverlayProps extends DrawerProps {
  header: React.ReactNode;
  width?: string;
}

const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = ({ children, header, width, ...rest }) => {
  return (
    <Drawer {...rest}>
      <DrawerOverlay />
      <DrawerContent maxW={width} p="24px 24px 0">
        <DrawerCloseButton color="#718096" right="24px" top="20px" />
        <DrawerHeader p={0} fontSize="18px">
          {header}
        </DrawerHeader>
        <Divider m="24px 0 8px" />
        <DrawerBody p={0}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Overlay;
