import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CheckAnswer, StartSession } from "@containers/Test/interface";

export default {
  startSession: (params: StartSession & { guid: string }) => request(METHODS.POST, API.SESSION.START)(params),
  finishSession: (guid: string) => request(METHODS.PATCH, API.SESSION.FINISH(guid))(),
  checkAnswer: (params: CheckAnswer) => request(METHODS.POST, API.TEST.CHECK_ANSWER)(params),
  getQuestions: (session_guid: string) => request(METHODS.GET, API.TEST.GET_QUESTIONS)({ params: { session_guid } }),
};
