import React, { FC } from "react";
import { RadioGroup, Radio as Option, Stack } from "@chakra-ui/react";
import { RadioInputProps } from "@shared/interfaces";

import "./index.scss";

export const Radio: FC<RadioInputProps> = ({ value, onChange, options, direction = "column", className }) => {
  return (
    <RadioGroup onChange={onChange} value={value}>
      <Stack direction={direction} className={`input-radio ${className || ""}`}>
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            className={option.className || ""}
            variant={option.variant}
            isDisabled={option.isDisabled}
            size="lg"
          >
            {option.label}
          </Option>
        ))}
      </Stack>
    </RadioGroup>
  );
};
