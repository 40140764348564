import React, { useState, FC } from "react";
import { Formik } from "formik";
import { RestoreShape } from "@containers/Auth/interface/AuthForm.interface";
import { FormProps } from "@shared/interfaces";
import { FormGenerator, Button } from "@shared/components";

import { validationSchema, fields, getInitValues } from "./formHelpers";

export interface RestoreFormProps {
  email: string | null;
}

const RestoreForm: FC<FormProps<RestoreShape> & RestoreFormProps> = (props) => {
  const { email } = props;
  const [formValues] = useState(getInitValues(email));
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      initialValues={formValues}
    >
      {(formikProps) => (
        <>
          <div className="auth-modal__hint">We’ll send you link to restore your password</div>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button
              className="auth-modal__submit"
              type="submit"
              isDisabled={props.loading || !validationSchema.isValidSync(formikProps.values)}
            >
              Send
            </Button>
          </FormGenerator>
        </>
      )}
    </Formik>
  );
};

export default RestoreForm;
