import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { ReactNode, MouseEventHandler, FC } from "react";
import { ButtonVariant } from "@shared/interfaces";

import "./index.scss";

interface ButtonProps extends Omit<ChakraButtonProps, "variant"> {
  onClick?: MouseEventHandler;
  children: ReactNode;
  variant?: ButtonVariant;
}

const Button: FC<ButtonProps> = ({ className, children, variant, ...props }) => {
  return (
    <ChakraButton className={`button ${className || ""}`} height="48px" {...props} variant={variant}>
      {children}
    </ChakraButton>
  );
};

export default Button;
