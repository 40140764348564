import React, { FC, useCallback, useEffect, useMemo, useState, Fragment } from "react";
import { Box, Divider } from "@chakra-ui/react";
import { Answer, Question as QuestionModel } from "@shared/models";
import { Radio, Button, Checkbox } from "@shared/components";
import { CheckboxInputOptionProps, RadioInputOptionProps } from "@shared/interfaces";
import { ReactComponent as Next } from "@assets/files/icons/general/next.svg";
import { ReactComponent as Previous } from "@assets/files/icons/general/previous.svg";
import { ReactComponent as Check } from "@assets/files/icons/general/check.svg";

import { QuestionFeedback } from "..";

import "./index.scss";

interface QuestionProps {
  question: QuestionModel;
  isLast: boolean;
  allQuestionsAnswered: boolean;
  submitHandler: (values: string[]) => void;
  finishAndQuitHandler: () => void;
  previousQuestionHandler?: () => void;
  startNewTestHandler?: () => void;
  answer?: Answer;
}
const Question: FC<QuestionProps> = ({
  question,
  isLast,
  allQuestionsAnswered,
  submitHandler,
  finishAndQuitHandler,
  answer,
  previousQuestionHandler,
  startNewTestHandler,
}) => {
  const [values, setValues] = useState<string[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const options = useMemo<Array<RadioInputOptionProps | CheckboxInputOptionProps>>(
    () =>
      [...question.options]
        .sort((a, b) => a.order - b.order)
        .map((option) => {
          return {
            value: String(option.id),
            label: option.value,
          };
        }),
    [question.options],
  );

  useEffect(() => {
    setValues(answer?.user_answers?.map(String) || []);
    setSubmitDisabled(false);
  }, [answer]);

  const onSubmit = useCallback(() => {
    setSubmitDisabled(true);
    submitHandler(values);
  }, [submitHandler, values]);

  return (
    <Box className="question">
      {answer ? (
        <QuestionFeedback question={question} answer={answer} singleQuestion />
      ) : (
        <>
          <Box className="question__text">
            {question.stem.split("\n").map((part, i) => (
              <Fragment key={i}>
                {part}
                <br />
              </Fragment>
            ))}
          </Box>
          {question.is_multiple ? (
            <Checkbox options={options} values={values} onChange={setValues} className="question__options" />
          ) : (
            <Radio options={options} value={values[0]} onChange={(v) => setValues([v])} className="question__options" />
          )}

          <Divider borderColor="#cbd5e0" mt="40px" className="question__divider" />
        </>
      )}
      <Box className="question__actions actions" mt="auto">
        {previousQuestionHandler && (
          <Button
            variant="purpleText"
            className="actions__previous"
            leftIcon={<Previous />}
            onClick={previousQuestionHandler}
          >
            Previous
          </Button>
        )}
        {!isLast && !startNewTestHandler && (
          <Button variant="purpleText" className="actions__finish" onClick={finishAndQuitHandler}>
            Finish{allQuestionsAnswered ? "" : " & Quit"}
          </Button>
        )}
        {startNewTestHandler && (
          <Button variant="purpleText" className="actions__finish" onClick={startNewTestHandler}>
            Start New Test
          </Button>
        )}
        <Button
          variant="solid"
          className="actions__submit"
          isDisabled={values.length !== question.correct_answers_count || submitDisabled}
          onClick={onSubmit}
          rightIcon={answer && !isLast ? <Next /> : undefined}
          leftIcon={answer && isLast ? <Check /> : undefined}
        >
          {!answer ? "Submit" : isLast ? "Finish" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default Question;
