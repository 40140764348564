export enum TestActionTypes {
  START_SESSION = "@@TEST/START_SESSION",
  START_SESSION_SUCCESS = "@@TEST/START_SESSION_SUCCESS",
  START_SESSION_FAILURE = "@@TEST/START_SESSION_FAILURE",
  START_SESSION_CANCEL = "@@TEST/START_SESSION_CANCEL",

  FINISH_SESSION = "@@TEST/FINISH_SESSION",
  FINISH_SESSION_SUCCESS = "@@TEST/FINISH_SESSION_SUCCESS",
  FINISH_SESSION_FAILURE = "@@TEST/FINISH_SESSION_FAILURE",

  GET_QUESTIONS = "@@TEST/GET_QUESTIONS",
  GET_QUESTIONS_SUCCESS = "@@TEST/GET_QUESTIONS_SUCCESS",
  GET_QUESTIONS_FAILURE = "@@TEST/GET_QUESTIONS_FAILURE",
  GET_QUESTIONS_CANCEL = "@@TEST/GET_QUESTIONS_CANCEL",

  CHECK_ANSWER = "@@TEST/CHECK_ANSWER",
  CHECK_ANSWER_SUCCESS = "@@TEST/CHECK_ANSWER_SUCCESS",
  CHECK_ANSWER_FAILURE = "@@TEST/CHECK_ANSWER_FAILURE",

  CLEAR_ANSWERS = "@@TEST/CLEAR_ANSWERS",

  CLEAR_ERRORS = "@@TEST/CLEAR_ERRORS",
}
